import CryptoJS from 'crypto-js';

export const getSha256DocumentHash = (file: ArrayBuffer): Promise<string> => {
  return new Promise((resolve, reject) => {
    try {
      
      const wa = CryptoJS.lib.WordArray.create(file);
      const hash = CryptoJS.SHA256(wa).toString();
      resolve(hash);
    } catch (e: any) {
      reject('error: invalid hash');
    }
  });
}
