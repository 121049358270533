import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Verifier } from "./templates/Verifier";
import Home from "./pages/home";
import './index.css';
import './App.css';

function App() {
  return (
      <Router>
          <Switch>
              <Route path="/" exact component={Home} />

              <Route exact path="/verifyhash/:id" render={(props) => (
                  <Verifier hash={props.match.params.id}/>
              )} />


          </Switch>
      </Router>
  );
}

export default App;
