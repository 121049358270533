
import { CenteredFooter } from '../../footer/CenteredFooter';
import { Section } from '../../layout/Section';
import { Logo } from './Logo';
import { useMediaQuery } from "react-responsive";
import { LogoDark } from "./LogoDark";

const Footer = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 640px)',
    });

    return (
        <Section>
            <CenteredFooter logo={isDesktopOrLaptop ? <Logo /> : <LogoDark/>}/>
        </Section>
    );
};

export { Footer };
