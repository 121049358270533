/* eslint-disable */
import ApiCaller from "../../lib/ApiCaller";
import DocumentsApi from "../../api/DocumentsApi";

interface DocumentVerificationData {
    documentUid: string;
    fileHash: string;
    fileName: string;
    originalFileHash: string;
    timestamp: string;
    userUid: string;
}

class Navocerts {
    src: any;
    certId: string;
    certVersionId: string;
    hash: string;

    constructor(src: any, certId?: string, certVersionId?: string, hash?: string) {
        this.certId = certId ?? "";
        this.certVersionId = certVersionId ?? "";
        this.hash = hash ?? "";
        this.src = src;
    }

    call(): Promise<void | any> {
        const docApi = new DocumentsApi(new ApiCaller());
        return docApi.verifyCredential(this.certVersionId).then((doc) => {
            if (doc.data.type?.[1] === "OPENBADGES") {
                this.src.certId = this.certId;
                this.src.hash = this.hash;
                this.src.badge.name = doc.data.credentialSubject.badge.name;
                this.src.badge.description = doc.data.credentialSubject.badge.description ?? "Port Clearance Documentation";
                this.src.issuedOn = doc.data.issuanceDate;
                this.src.expires = doc.data.expirationDate
                // this.src.recipient.identity = doc.captainId;
                this.src.recipientProfile.name = doc.data.credentialSubject.recipientProfile.name;
                // // if we modify schema, then validation is broken
                this.src.STATUS = doc.status;
                this.src.badge.issuer.email = doc.data.credentialSubject.badge.issuer.email;
                this.src.badge.issuer.image = doc.data.credentialSubject.badge.issuer.image;
                this.src.badge.issuer.name = doc.data.credentialSubject.badge.issuer.name;
                //this.src = doc.credentialSubject
            } else if ('documentUid' in doc) { // verification with raw.icp0.io
                this.src.hash = this.hash;
                this.src.badge.name = (doc as DocumentVerificationData).fileName;
                this.src.issuedOn = (new Date(parseInt((doc as DocumentVerificationData).timestamp))).toISOString();
                this.src.STATUS = "SKIP_REVOCATION_LIST_CHECK";
            } else {
                this.src.badge.name = "Document";
                this.src.badge.description = "Scanned document file using navocerts.";
                this.src.issuedOn = doc.data.issuanceDate;
                this.src.recipientProfile.name = doc.data.credentialSubject.recipientName;
                this.src.badge.issuer.email = "info@navocerts.com"
                this.src.badge.issuer.image = ''
                this.src.badge.issuer.name = doc.data.issuer;
            }
            return this.src;
        }).catch((err) => {
            console.error(err.toString())
            this.src.badge.name = '';
            this.src.badge.description = '';
            this.src.badge.issuer.email = '';
            this.src.badge.issuer.image = '';
            this.src.badge.issuer.name = '';
            this.src.issuedOn = '';
            this.src.recipient.identity = '';
            this.src.recipientProfile.name = '';
            this.src.STATUS = err.message;
            this.src.badge.issuer.email = 'UNKNOWN';
            return this.src;
        })
    }

    verifyRevoked(hash: string): Promise<boolean> {
        const docApi = new DocumentsApi(new ApiCaller());
        return docApi.getRevocationList(hash).then((revocationList) => {
            const assertions = revocationList.revokedAssertions;
            return !!(assertions && assertions.length);
        }).catch((err) => {
            console.error(err.message);
            throw err;
        })
    }
}

export default Navocerts;
