import { scanFile } from "../pdfscan";
import { verifyCredentialByVersionUid } from "./ApiCommands";
import { getSha256DocumentHash } from "./getDocumentHash";

export async function retrieve(dropFile: any): Promise<any> {
    const retrieved = { qr: '', certVersionId: '', certId: '', qrFileHash: '', equalHash: false };
    let resultBody = { status: 'ok', message: 'File uploaded successfully', retrieved };
    try {
        const retrievedQR: string | null = await scanFile(dropFile);
        const blob = new Response(dropFile);
        const blobBff = await blob.blob();
        const file = await blobBff.arrayBuffer();
        const qrFileHash = await getSha256DocumentHash(file);
        const credentialVersionId = retrievedQR!.substring(retrievedQR!.lastIndexOf('/') + 1);
        const credentialInfo = await verifyCredentialByVersionUid(credentialVersionId);
        const equalHash = credentialInfo.fileHash === qrFileHash;
        resultBody = {
            ...resultBody,
            retrieved: {
                qr: retrievedQR!,
                certId: credentialInfo.data.credentialSubject.id,
                certVersionId: credentialVersionId,
                qrFileHash: qrFileHash,
                equalHash: equalHash,
            },
        };
    } catch (e: any) {
        let msg = '';
        if (e?.name === "InvalidPDFException") msg = "Invalid PDF";
        else if (e instanceof Event) msg = "Invalid Image";
        else msg = "we can't access the file";
        resultBody = {
            status: 'fail', message: msg, retrieved: retrieved,
        };
    }

    return resultBody;
}
