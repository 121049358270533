import ApiCaller from '../lib/ApiCaller';

const CANISTER_ID = 'mw7hy-bqaaa-aaaak-qc6uq-cai';

class DocumentsApi {

    apiCaller: ApiCaller;

    constructor(apiCaller: ApiCaller) {
        this.apiCaller = apiCaller;
    }

    getCredentialPayload(id: string, hash: string): Promise<void | any> {
        const url = '/public/credentials/' + id + '?format=json';
        const canisterUrl = `https://${CANISTER_ID}.raw.icp0.io/hash/${hash}`;
        console.log('Get Credential');
        const result = this.apiCaller.call(url, 'GET')
            .then(async (res: any) => {
                if (res.error) {
                    return await this.apiCaller.callGetExternal(canisterUrl)
                        .catch((err) => {
                            throw Error("INVALID_CREDENTIAL");
                    });
                }
                return res;
            }).catch(async () => {
                return await this.apiCaller.callGetExternal(canisterUrl)
                    .catch((err) => {
                        throw Error("INVALID_CREDENTIAL");
                    });
            });
        return result;
    }

    getCredentialDocument(id: string): Promise<void | any> {
        const url = `/documents/download/${id}`;
        console.log('Get credential document');

        return this.apiCaller.download(url, 'GET').then((file) => {
            return file;
        });
    }

    getRevocationList(id: string): Promise<void | any> {
        const url = '/public/crl/credential/' + id;
        console.log('Get revocations list');
        return this.apiCaller.call(url, 'GET').then((res) => {
            return res;
        });
    }

    verifyCredential(versionUid: string): Promise<void | any> {
        const url = '/verify/' + versionUid;
        console.log('Verify credential');
        return this.apiCaller.call(url, 'GET').then((res) => {
            return res;
        });
    }
}

export default DocumentsApi;
