import { ButtonStatus } from "../button/ButtonStatus";

export enum CredentialStatus {
    success = 'success',
    inactive = 'inactive',
    revoked = 'revoked',
    expired = 'expired',
    error = 'error',
}

type IStatusProps = {
    verificationStatus?: CredentialStatus;
};

const VerificationStatus = (props: IStatusProps) => {
    const getStatusLabel = (status: CredentialStatus | '') => {
        switch (status) {
            case CredentialStatus.success:
                return <ButtonStatus bold xl type="success">Verified</ButtonStatus>;
            case CredentialStatus.inactive:
                return <ButtonStatus bold xl type="invalid">Invalid</ButtonStatus>;
            case CredentialStatus.revoked:
                return <ButtonStatus bold xl type="invalid">Revoked</ButtonStatus>;
            case CredentialStatus.expired:
                return <ButtonStatus bold xl type="invalid">Expired</ButtonStatus>;
            case CredentialStatus.error:
                return <ButtonStatus bold xl type="invalid">Unable to Verify. Network Error</ButtonStatus>;
            default:
                return <></>;
        }
    };

    return (
        <section>
            {getStatusLabel(props.verificationStatus ?? "")}
        </section>
    );
};

export { VerificationStatus };
