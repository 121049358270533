
const Logo = () => {
  return (
    <span className={`text-gray-900 inline-flex items-center`}>
        <svg width="100" height="auto" viewBox="0 0 284 105" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_6416_7760)">
        <path d="M74.7284 0.818008H29.2537C13.2976 0.818008 0.362671 13.753 0.362671 29.709V75.1837C0.362671 91.1398 13.2976 104.075 29.2537 104.075H74.7284C90.6844 104.075 103.619 91.1398 103.619 75.1837V29.709C103.619 13.753 90.6844 0.818008 74.7284 0.818008Z" fill="#05738C"/>
        <path d="M49.1337 27.2781C49.1337 27.2781 49.121 27.1763 49.0573 27.1763H47.7464C46.3591 27.1126 45.0737 27.9272 44.5646 29.2254L25.0536 77.5255C25.0536 77.5255 25.0663 77.6273 25.13 77.6273H26.53C27.8663 77.6273 29.0627 76.8128 29.5718 75.5783L49.1464 27.2781H49.1337Z" fill="#08B8DF"/>
        <path d="M86.412 45.5036L86.3484 37.0145C86.3484 36.8745 86.1447 36.8363 86.0938 36.9636L69.6756 77.6019C69.6756 77.6019 69.701 77.7292 69.7647 77.7292H71.0756C72.4501 77.7292 73.6847 76.9019 74.1938 75.6292L86.3993 45.5418C86.3993 45.5418 86.3993 45.5163 86.3993 45.5036H86.412Z" fill="#08B8DF"/>
        <path d="M41.7009 27.2781C41.7009 27.2781 41.6882 27.1763 41.6246 27.1763H40.3137C38.9264 27.1126 37.6409 27.9272 37.1318 29.2254L17.6209 77.5255C17.6209 77.5255 17.6336 77.6273 17.6972 77.6273H19.0972C20.4336 77.6273 21.63 76.8128 22.1391 75.5783L41.7137 27.2781H41.7009Z" fill="#08B8DF"/>
        <path d="M86.2593 27.2781C86.2593 27.2781 86.2465 27.1763 86.1829 27.1763H84.872C83.4847 27.1126 82.1993 27.9272 81.6902 29.2254L62.1792 77.5255C62.1792 77.5255 62.1919 77.6273 62.2555 77.6273H63.6556C64.9919 77.6273 66.1883 76.8128 66.6974 75.5783L86.272 27.2781H86.2593Z" fill="#08B8DF"/>
        <path d="M34.2809 27.3417C34.3191 27.2526 34.2555 27.1635 34.1664 27.1635H32.9445C31.5573 27.0999 30.2718 27.9145 29.7627 29.2126L17.5954 59.3255C17.5954 59.3255 17.5954 59.3637 17.5954 59.3764L17.6845 67.7128C17.6845 67.8528 17.8754 67.891 17.939 67.7637L34.3064 27.3545L34.2809 27.3417Z" fill="#08B8DF"/>
        <path d="M59.8246 53.4709L51.1191 31.9363C51.1191 31.9363 51.0046 31.8727 50.9791 31.9363L49.6937 35.0927C49.3628 35.9072 49.3628 36.8108 49.6937 37.6254L58.4628 59.1218C58.4883 59.1855 58.5774 59.1855 58.6028 59.1218L59.8374 55.9527C60.1428 55.1636 60.1428 54.2727 59.8246 53.4836V53.4709Z" fill="#08B8DF"/>
        <path d="M56.0446 62.7873L47.3519 41.2654C47.3519 41.2654 47.2373 41.2018 47.2119 41.2654L45.9264 44.4218C45.5955 45.2363 45.5955 46.14 45.9264 46.9545L54.6701 68.4C54.6701 68.4 54.7846 68.4637 54.8101 68.4L56.0446 65.3073C56.3628 64.5055 56.3628 63.6146 56.0446 62.8128V62.7873Z" fill="#08B8DF"/>
        <path d="M74.3083 29.3145L56.541 73.2746C56.3246 73.7964 55.8155 74.1401 55.2555 74.1401H54.2373C53.6773 74.1401 53.1682 73.7964 52.9519 73.2746L43.6737 50.2764C43.6737 50.2764 43.5591 50.2127 43.5337 50.2764L42.2482 53.4327C41.9173 54.2473 41.9173 55.1509 42.2482 55.9655L50.2664 75.6419C50.7755 76.9019 52.0101 77.7292 53.3719 77.7292H54.7337L54.6955 77.6401H54.7592L54.721 77.7292H56.1846C57.5464 77.7292 58.781 76.9019 59.2901 75.6419L78.9156 27.2781C78.9156 27.2781 78.9029 27.1763 78.8392 27.1763H77.4647C76.0774 27.1126 74.792 28.0417 74.2829 29.3272L74.3083 29.3145Z" fill="#08B8DF"/>
        <path d="M120.928 52.6182C120.928 37.549 129.252 26.7435 144.805 26.7435C158.219 26.7435 165.372 34.7236 166.543 46.14H153.879C152.989 40.769 150.787 36.849 144.245 36.849C136.061 36.849 133.579 43.1745 133.579 52.4018C133.579 61.6291 136.061 68.0182 144.245 68.0182C150.85 68.0182 153.052 64.0982 153.879 58.5873H166.543C165.449 69.8764 158.016 78.1237 144.461 78.1237C128.845 78.1237 120.928 67.2546 120.928 52.6055V52.6182Z" fill="white"/>
        <path d="M168.821 67.8273C168.821 60.5346 174.803 57.7091 182.312 56.1309C186.169 55.3036 191.603 54.7564 191.603 51.2436C191.603 49.3218 190.089 48.0745 186.92 48.0745C182.86 48.0745 181.001 49.7291 180.658 53.1654H170.196C170.54 46.42 175.49 40.6418 187.391 40.6418C196.949 40.6418 202.256 44.4981 202.256 55.2909V68.9091C202.256 70.7673 202.46 71.5946 203.567 71.5946C203.847 71.5946 204.051 71.5946 204.458 71.531V76.7619C202.256 77.1692 199.991 77.3855 198.4 77.3855C193.996 77.3855 192.341 75.731 191.794 72.7782H191.654C189.172 75.871 185.112 78.1492 180.021 78.1492C173.136 78.1492 168.809 74.0892 168.809 67.8273H168.821ZM191.591 62.8V59.8473C190.152 60.3946 188.498 60.8146 185.609 61.5018C181.549 62.4691 180.11 64.1873 180.11 66.6564C180.11 69.3419 181.829 70.5764 184.654 70.5764C189.058 70.5764 191.603 66.86 191.603 62.8H191.591Z" fill="white"/>
        <path d="M245.733 59.4273C245.733 70.9837 239.751 78.1364 230.396 78.1364C225.305 78.1364 221.589 75.3873 220.214 73.5291H220.074V89.2092H209.065V41.8127H220.074V45.3254H220.214C221.589 43.4672 225.305 40.7181 230.396 40.7181C239.751 40.7181 245.733 47.8709 245.733 59.4273ZM234.456 59.4273C234.456 52.5418 231.847 48.9654 227.367 48.9654C222.887 48.9654 220.278 52.5418 220.278 59.4273C220.278 66.3128 222.887 69.8891 227.367 69.8891C231.847 69.8891 234.456 66.3128 234.456 59.4273Z" fill="white"/>
        <path d="M259.796 40.4254V28.5635H255.38V26.7435H266.338V28.5635H261.922V40.4254H259.796Z" fill="white"/>
        <path d="M269.902 40.4254V26.7563H272.676L276.622 37.5108H276.66L280.618 26.7563H283.393V40.4254H281.331V29.8108H281.293L277.525 40.4254H275.782L272.014 29.8108H271.976V40.4254H269.914H269.902Z" fill="white"/>
        </g>
        <defs>
        <clipPath id="clip0_6416_7760">
        <rect width="283.017" height="103.257" fill="white" transform="translate(0.362671 0.818008)"/>
        </clipPath>
        </defs>
        </svg>
    </span>
  );
};

export { Logo };
