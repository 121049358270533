import ApiCaller from '../lib/ApiCaller';
import DocumentsApi from '../api/DocumentsApi';

export const downloadCertificateFile = (id: string): Promise<any> => {
    const documentApi = new DocumentsApi(new ApiCaller());

    return documentApi.getCredentialDocument(id).then((file) => {
        return file;
    }).catch((err) => {
        throw Error('Error retrieving file hash.')
    });
}

export const verifyCredentialByVersionUid = (id: string): Promise<any> => {
    const documentApi = new DocumentsApi(new ApiCaller());

    return documentApi.verifyCredential(id).then((file) => {
        return file;
    }).catch((err) => {
        throw Error('Error verifying credential.')
    });
}
