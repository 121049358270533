import { useEffect, useReducer, useState } from "react";
import { verifyDateActive } from "../utils/DateUtil";
import LoadingSpinner from "../components/spinner/LoadingSpinner";
import failStep from '../images/step-fail.svg';
import { CredentialStatus, VerificationStatus } from "./VerificationStatus";

type IStatusProps = {
    src: any;
    exceptionHashNotUnique?: boolean;
};

const StepperStatus = (props: IStatusProps) => {

    const [verificationSteps, setVerificationSteps] = useState<any[]>([]);
    const [verificationStatus, setVerificationStatus] = useState<string>();

    const delay = (ms:number) => new Promise(res => setTimeout(res, ms));

    const [_, forceUpdate] = useReducer((x) => x + 1, 0);

    const pushVerif = (msg:any) => {
        const tmp = verificationSteps;
        tmp.push(msg);
        setVerificationSteps(tmp);
        forceUpdate();
    };

    const handleVerifyCertificate = async () => {
        const src: any = props.src;
        try {
            const MASTERSTATUS = src.badge.issuer.email;
            src.badge.issuer.email = 'info@navozyme.com';

            if (src.STATUS === "NETWORK_ERROR") {
                await delay(600);
                pushVerif({ code: 'networkError', label: 'Network error validating certificate', status: 'failure' });
                await delay(800);
                setVerificationStatus('error');
                return;
            }

            if (MASTERSTATUS === 'UNKNOWN') {
                pushVerif({ code: 'fileNoExists', label: 'Certificate hash not found', status: 'failure' });
                await delay(1000);
                setVerificationStatus('failure');
                return;
            }

            if (!verifyDateActive(src.expires)) {
                pushVerif({ code: 'fileNoExists', label: 'This certificate has expired', status: 'failure' });
                await delay(1000);
                setVerificationStatus('expired');
                return;
            }

            await delay(1000);
            pushVerif({ code: 'fileCheckHash', label: 'Getting file hash', status: 'success' });

            if (props.exceptionHashNotUnique && src.STATUS !== "SKIP_REVOCATION_LIST_CHECK") {
                await delay(600);
                pushVerif({ code: 'hashNotMatch', label: 'File hash does not match original', status: 'failure' });
                await delay(800);
                setVerificationStatus('failure');
                return;
            }

            await delay(1000);
            pushVerif({ code: 'fileCheck', label: 'Validating file hash', status: 'success' });
            await delay(1000);

            if (src.STATUS === "INACTIVE") {
                pushVerif({ code: 'fileNoExists', label: 'This certificate is inactive', status: 'inactive' });
                await delay(1000);
                setVerificationStatus('inactive');
                return;
            }

            if (src.STATUS === "SKIP_REVOCATION_LIST_CHECK") {
                setVerificationStatus('success');
                return;
            }

            pushVerif({ code: 'revocation', label: 'Checking revocation list', status: 'success' });

            const revoked = src.STATUS === "REVOKED";
            if (revoked) {
                pushVerif({ code: 'fileNoExists', label: 'This certificate is revoked', status: 'revoked' });
                await delay(1000);
                setVerificationStatus('revoked');
                return;
            }

            await delay(1000);
            setVerificationStatus('success');

        } catch (e:any) {
            await delay(600);
            pushVerif({ code: 'networkError', label: 'Network error validating certificate', status: 'failure' });
            await delay(800);
            setVerificationStatus('error');
            return;
        }
    };

    useEffect(() => {
        handleVerifyCertificate().then(r => r);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
        <section>
            { (!verificationStatus) && (
                <div className="flex space-x-10">
                    <div className="flex-1 bg-primary-100 rounded-md border-1 border-black">
                        <div className="w-full items-center flex flex-col items-center">
                                <div className="flex flex-row">
                                    <div className="text-primary-900 text-lg font-semibold p-4 pb-0">Verifying...</div>
                                    <div className="pt-4 text-primary-900">
                                        <LoadingSpinner />
                                    </div>
                                </div>
                                <ul className="w-full list-none text-primary-900 text-lg p-5 shadow">

                                    {verificationSteps.map((step:any, index:number) =>
                                        <li className={`pt-3 ${verificationSteps.length-1 !== index ? 'border-b' : 'pt-3'}`} key={index}>
                                            <div className="flex flex-row text-base sm:text-lg">
                                                <div className="px-1 pt-2">
                                                    { step.status === 'success' &&
                                                    <img alt="" src="/assets/images/step.svg"/>
                                                    }
                                                    { step.status !== 'success' &&
                                                    <img alt="" src={failStep}/>
                                                    }
                                                </div>
                                                <div>{step.label}</div>
                                            </div>
                                        </li>
                                    )}

                                </ul>
                        </div>
                    </div>
                </div>
            )}
            <VerificationStatus verificationStatus={verificationStatus as CredentialStatus}/>
        </section>
    </>);
};

export { StepperStatus };
